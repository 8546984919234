import React, { useEffect, useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import axiosInstance from "@/lib/axios";
import usePackageStore from "@/store/usePackageStore";
import { IPayment, Window, PaymentStatus, PaymentMethod } from "@/types/types";

// Define proper types for Payment Request API
interface SamsungPayData {
  version: string;
  productId: string;
  allowedCardNetworks: string[];
  merchantName: string;
  paymentProtocol?: string;
  orderNumber?: string;
  billingAddressRequired?: boolean;
  merchantGatewayParameter?: Record<string, string>;
}

const BuyPackage: React.FC = () => {
  const { carId, packageId, packagePrice } = usePackageStore();
  const navigate = useNavigate();
  const [cookies] = useCookies(["jwt"]);
  const [isSamsungPaySupported, setIsSamsungPaySupported] = useState(false);
  const [samsungPayStatus, setSamsungPayStatus] = useState("");
  const [showDebugInfo, setShowDebugInfo] = useState(true);

  const handlePaymentCompletion = useCallback(
    async (payment: IPayment): Promise<void> => {
      setSamsungPayStatus(`Handling payment: ${JSON.stringify(payment)}`);
      return new Promise((resolve, reject) => {
        if (!packageId) {
          const error = new Error("Missing package ID");
          setSamsungPayStatus(`Error: ${error.message}`);
          reject(error);
          return;
        }

        if (payment.status === PaymentStatus.initiated || payment.status === PaymentStatus.paid) {
          setSamsungPayStatus(`Posting to /package/buy with paymentId: ${payment.id}`);
          axiosInstance
            .post("/package/buy", {
              paymentId: payment.id,
            })
            .then((response) => {
              setSamsungPayStatus(`Response status: ${response.status}`);
              if (response.status === 201) {
                resolve();
              } else {
                const error = new Error(`Unexpected status code: ${response.status}`);
                setSamsungPayStatus(`Error: ${error.message}`);
                reject(error);
              }
            })
            .catch((error) => {
              console.error("Error processing package purchase: ", error);
              setSamsungPayStatus(`Error: ${(error as Error).message || 'Unknown error'}`);
              reject(error);
            });
        } else {
          const error = new Error(`Invalid payment status: ${payment.status}`);
          setSamsungPayStatus(`Error: ${error.message}`);
          reject(error);
        }
      });
    },
    [packageId],
  );

  const initializeMoyasar = useCallback(() => {
    const windows = window as Window;
    if (!windows?.Moyasar) return;

    try {
      windows.Moyasar.init({
        element: ".mysr-form",
        amount: 100 * packagePrice,
        currency: "SAR",
        description: "Bluewash Package Purchase",
        publishable_api_key: import.meta.env.VITE_MOYASSER_API_KEY,
        callback_url: `${import.meta.env.MODE === "development" ? "http://localhost:5173/packages/my-packages" : `${import.meta.env.VITE_HOST_URL}/packages/my-packages`}`,
        methods: ["creditcard", "applepay", "stcpay"],
        metadata: {
          type: "package",
          packageId: packageId,
          carId: carId,
          token: cookies.jwt,
        },
        apple_pay: {
          country: "SA",
          label: "Bluewash Package",
          validate_merchant_url: "https://api.moyasar.com/v1/applepay/initiate",
        },
        on_completed: handlePaymentCompletion,
      });
    } catch (error) {
      console.error("Error initializing Moyasar:", error);
      setSamsungPayStatus(`Moyasar error: ${(error as Error).message || 'Unknown error'}`);
    }
  }, [carId, packageId, cookies.jwt, packagePrice, handlePaymentCompletion]);

  // Check Samsung Pay support
  const checkSamsungPaySupport = useCallback(async () => {
    setSamsungPayStatus("Checking Samsung Pay support...");
    
    if (!window.PaymentRequest) {
      setSamsungPayStatus("Payment Request API not supported");
      setIsSamsungPaySupported(false);
      return;
    }

    try {
      // Display environment variables for debugging
      const serviceId = import.meta.env.VITE_SAMSUNG_PAY_SERVICE_ID;
      setSamsungPayStatus(`Service ID from env: ${serviceId || "NOT FOUND"}`);
      
      if (!serviceId) {
        setSamsungPayStatus("ERROR: VITE_SAMSUNG_PAY_SERVICE_ID not found in environment variables");
        setIsSamsungPaySupported(false);
        return;
      }

      // Try with required data for Samsung Pay
      const methodData: PaymentMethodData[] = [{
        supportedMethods: "https://spay.samsung.com",
        data: {
          version: "1",
          productId: serviceId,
          allowedCardNetworks: ["mastercard", "visa"],
          merchantName: "Bluewash", // Must match name in Samsung Pay portal
        } as SamsungPayData
      }];

      const details: PaymentDetailsInit = {
        total: {
          label: "Test",
          amount: { currency: "SAR", value: "1.00" }
        }
      };

      const request = new PaymentRequest(methodData, details);
      const canPay = await request.canMakePayment();
      
      setIsSamsungPaySupported(!!canPay);
      setSamsungPayStatus(canPay ? "Samsung Pay is available" : "Samsung Pay is not available");
    } catch (error) {
      console.error("Error checking Samsung Pay support:", error);
      setSamsungPayStatus(`Samsung Pay check error: ${(error as Error).message || 'Unknown error'}`);
      setIsSamsungPaySupported(false);
    }
  }, []);

  // Handle Samsung Pay payment
  const handleSamsungPay = async () => {
    if (!isSamsungPaySupported || !packageId) {
      setSamsungPayStatus("Samsung Pay is not supported or package ID is missing");
      return;
    }

    try {
      setSamsungPayStatus("Initializing Samsung Pay...");
      
      // Get the service ID - VERY IMPORTANT this is correct
      const serviceId = import.meta.env.VITE_SAMSUNG_PAY_SERVICE_ID;
      
      if (!serviceId) {
        setSamsungPayStatus("ERROR: VITE_SAMSUNG_PAY_SERVICE_ID not found in environment variables");
        return;
      }
      
      setSamsungPayStatus(`Using Service ID: ${serviceId}`);
      
      // Setup payment request with MINIMAL required fields
      const methodData: PaymentMethodData[] = [{
        supportedMethods: "https://spay.samsung.com",
        data: {
          version: "1", 
          productId: serviceId,
          allowedCardNetworks: ["mastercard", "visa"],
          merchantName: "Bluewash", // EXACT match with portal
          paymentProtocol: "PROTOCOL_3DS"
        } as SamsungPayData
      }];

      // Format price correctly - ensure it's a string with 2 decimal places
      const formattedPrice = (packagePrice || 0).toFixed(2);
      setSamsungPayStatus(`Price: ${formattedPrice} SAR`);
      
      // Minimal details object
      const details: PaymentDetailsInit = {
        total: {
          label: "Total",
          amount: { currency: "SAR", value: formattedPrice }
        }
      };

      // Create payment request with minimal options
      const request = new PaymentRequest(methodData, details);
      setSamsungPayStatus("Opening Samsung Pay...");
      
      // Show the payment sheet
      const response = await request.show();
      setSamsungPayStatus("Got response from Samsung Pay");
      
      // Here we would normally process with backend
      try {
        // Create a simple mock payment for testing
        const mockPaymentId = `samsung-${Date.now()}`;
        setSamsungPayStatus(`Created mock payment ID: ${mockPaymentId}`);
        
        // Complete the payment UI first to prevent hanging
        await response.complete("success");
        setSamsungPayStatus("Payment UI completed");
        
        // Then process with our handler - Using the correct enum values
        await handlePaymentCompletion({
          id: mockPaymentId,
          status: PaymentStatus.paid,
          // Add other required properties with default values
          amount: packagePrice * 100,
          fee: 0,
          ip: "",
          source: {
            type: PaymentMethod.creditcard  // Using the enum value here
          },
          captured: 0,
          updated_at: new Date().toISOString(),
          created_at: new Date().toISOString()
        });
        
        setSamsungPayStatus("Payment processed successfully");
        // Navigate to success page
        navigate("/packages/my-packages");
      } catch (error) {
        setSamsungPayStatus(`Payment processing error: ${(error as Error).message || 'Unknown error'}`);
        // Try to complete the UI to prevent hanging
        try {
          await response.complete("fail");
        } catch (e) {
          setSamsungPayStatus(`Additional error completing UI: ${(e as Error).message}`);
        }
      }
    } catch (error) {
      console.error("Samsung Pay error:", error);
      setSamsungPayStatus(`Samsung Pay error: ${(error as Error).message || 'Unknown error'}`);
    }
  };

  useEffect(() => {
    if (!packageId) {
      navigate("/packages");
      return;
    }

    initializeMoyasar();
    checkSamsungPaySupport();
  }, [packageId, navigate, initializeMoyasar, checkSamsungPaySupport]);

  // For debugging - tap 5 times on status to toggle
  const handleStatusTap = () => {
    setShowDebugInfo(!showDebugInfo);
  };

  if (!packageId) {
    console.log("Missing package data:", { carId, packageId });
    navigate("/packages");
    return null;
  }

  return (
    <div className="container p-8">
      <h1 className="mb-6 text-2xl font-bold text-primary">شراء الباقة</h1>
      
      <div className="mb-6">
        <button 
          onClick={handleSamsungPay}
          disabled={!isSamsungPaySupported}
          className={`mb-3 w-full rounded p-3 text-white ${isSamsungPaySupported ? 'bg-black' : 'bg-gray-400'}`}
        >
          Pay with Samsung Pay
          {!isSamsungPaySupported && " (Not Supported)"}
        </button>
        
        {/* Debug info - viewable on phone */}
        {showDebugInfo && samsungPayStatus && (
          <div 
            onClick={handleStatusTap} 
            className="mb-4 mt-2 rounded border border-gray-300 bg-gray-100 p-3 text-xs text-gray-700"
          >
            <p>Debug Info (tap to hide):</p>
            <p>{samsungPayStatus}</p>
          </div>
        )}
      </div>
      
      <div className="mysr-form"></div>
    </div>
  );
};

export default BuyPackage;